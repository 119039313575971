import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
import { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import { ContextData } from "../ContextData";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from 'yup';

const Home = () => {
    const navigate = useNavigate();
	const [stillSubmitting, setStillSubmitting] = useState(false);
    const [searchParams] =  useSearchParams();

	const formSchema = Yup.object().shape({
		first_name: Yup.string().min(2,'Too Short!').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('First Name cannot be empty'),
		last_name: Yup.string().min(2,'Too Short!').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ").required('Last Name cannot be empty'),
		email: Yup.string().email('Invalid email').matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format').required('Email cannot be empty'),
		phone: Yup.string().min(10,'Minimum 10 digits required').max(21, 'Maximum 15 digits allowed').required('Phone cannot be empty')
	})


    const { setFormData } = useContext(ContextData);

	function phoneFormat(input) {
		input = input.replace(/\D/g, "");
		input = input.substring(0, 15);
		var size = input.length;
		if (size == 0) {
			input = input;
		} else if (size < 4) {
			input = "(" + input;
		} else if (size < 7) {
			input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
		} else {
			input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + " - " + input.substring(6, 15);
		}
		return input;
	}



    function onFormSubmit(data) {
		const dataFromFrom = {
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			phone: '+1 '+data.phone.replace(/[-() ]/g, '').replace(' ',''),
            campaignFrom: "ls"
		}

        if( searchParams.get("utm_source") ){
            dataFromFrom['utm_source'] = searchParams.get("utm_source");
        }

		setFormData(
			data
		);
        


			fetch('https://new.dev.klizos.com/api/create-lead', {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Basic ' + btoa('amardeep@klizos.com:password')
				},
				body: JSON.stringify(dataFromFrom),
			})
			.then((response) => response.json())
			.then((data) => {
				
				navigate('/thankyou')
			})
			.catch((error) => {
				setStillSubmitting(false)
			});
		
		


	}
    

    return (
        <main>
            <section className="section1">
                <div className="container text-center">
                    <h1 className="title">
                        Get a Free Professional Logo Today!
                    </h1>
                    <p>
                        A business is nothing without a logo. We believe in
                        startups and founders so much, that we will help you
                        launch or rebrand that business with a logo that speaks.
                    </p>
                </div>
            </section>

            <section className="section2" id="get-my-free-logo">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-end">
                            <img
                                src="assets/images/form-left.png"
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                        <div className="col-md-5">
                            <Formik
                                initialValues={{
                                    first_name: '',
                                    last_name:'',
                                    phone : '',
                                    email: '',
                                }}
								validationSchema={formSchema}
								onSubmit={(values, { setSubmitting }) => {
									setStillSubmitting(true);
									setTimeout(() => {
										onFormSubmit(values);
									  //alert(JSON.stringify(values, null, 2));
									  setSubmitting(false);
									}, 400);
								}}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (
                                    <Form
                                        noValidate
                                        className="fomrWrpr ps-md-4"
                                        onSubmit={handleSubmit}
                                    >
                                        {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}

                                        <h2 className="title">
                                            Fill the form:
                                        </h2>

                                        <div className="formGroup">
                                            <Form.Label className="labelControl">
                                                First Name*
                                            </Form.Label>
                                            <Form.Control
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.first_name.trim()}
                                                type="text"
                                                name="first_name"
                                                autoFocus
                                                className="formControl rounded-0"
                                                placeholder="John"
												isValid={touched.first_name && !errors.first_name}
												isInvalid={!!errors.first_name}
                                            />                                           
                                            

											{errors.first_name && touched.first_name ? (

											<div className="customInvalid">{errors.first_name}</div>

											) : null}
                                        </div>

                                        <div className="formGroup">
                                            <Form.Label className="labelControl">
                                                Last Name*
                                            </Form.Label>
                                            <Form.Control
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.last_name.trim()}
                                                type="text"
                                                name="last_name"
                                                className="formControl rounded-0"
                                                placeholder="Doe"
												isValid={touched.last_name && !errors.last_name}
												isInvalid={!!errors.last_name}
                                            />
                                            {errors.last_name && touched.last_name ? (

											<div className="customInvalid">{errors.last_name}</div>

											) : null}
                                        </div>

                                        <div className="formGroup">
                                            <Form.Label className="labelControl">
                                                Email*
                                            </Form.Label>
                                            <Form.Control
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                type="email"
                                                name="email"
                                                required
                                                className="formControl rounded-0"
                                                placeholder="email@domain.com"
												isValid={touched.email && !errors.email}
												isInvalid={!!errors.email}
                                            />
                                            {errors.email && touched.email ? (

											<div className="customInvalid">{errors.email}</div>

											) : null}
                                        </div>

                                        <div className="formGroup">
                                            <Form.Label className="labelControl">
                                                Phone*
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text className=" border-0">+1 </InputGroup.Text>
                                                <Form.Control
                                                    type="tel"
                                                    name="phone"
                                                    required
                                                    className={`formControl rounded-0 w-auto`}
                                                    placeholder="Phone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={phoneFormat(values.phone)}
                                                    isValid={touched.phone && !errors.phone}
                                                    isInvalid={!!errors.phone}
                                                />
                                            </InputGroup>
											
                                            {errors.phone && touched.phone ? (

											<div className="customInvalid">{errors.phone}</div>

											) : null}
                                        </div>

                                        <div className="formGroup mb-0">
                                            <button
                                                type="submit"
                                                className="btn submitBtn"
												disabled={stillSubmitting || isSubmitting}
                                            >
                                                <span className="me-2">
                                                    {isSubmitting
                                                        ? "Redirecting..."
                                                        : "GET MY FREE LOGO"}
                                                </span>{" "}
                                                <img
                                                    src="assets/images/right-arrow.png"
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                            </button>
                                        </div>

                                        <div className="terms">
                                            <p>
                                                <b>Terms:</b> By signing up for
                                                this logo, you agree to our
                                                terms and privacy policy. We are
                                                committed to providing value to
                                                our clients and keeping their
                                                information private.
                                            </p>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>

            <section className="section3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={16}
                                autoplay={{
                                    delay: 2500,
                                }}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    560: {
                                        slidesPerView: 3,
                                    },
                                }}
                                modules={[Pagination]}
                            >
                                <SwiperSlide>
                                    <img
                                        src="assets/images/s1.png"
                                        className="w-100"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="assets/images/s2.png"
                                        className="w-100"
                                        alt=""
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src="assets/images/s3.png"
                                        className="w-100"
                                        alt=""
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>

            <section className="section4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <h2 className="title">Why does a logo matter?</h2>

                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="matterList">
                                        <li>
                                            <img
                                                src="assets/images/m1.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            The best thing to start a business
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m2.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Solidifies your vision
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m3.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Speaks a brand / recognition to your
                                            clients
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m4.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Allows you to create business cards
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m5.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Allows you to dial in your colors
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m6.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Start drafting a website design
                                        </li>
                                        <li>
                                            <img
                                                src="assets/images/m7.png"
                                                className="img-fluid"
                                                alt=""
                                            />{" "}
                                            Create a business presentation or
                                            investment deck
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <img
                                        src="assets/images/d1.png"
                                        className="img-fluid"
										alt=""
                                    />
                                </div>

                                <div className="col-md-12 text-center mt-4">
                                    <a
                                        href="#get-my-free-logo"
                                        className="btn submitBtn"
                                    >
                                        <span className="me-2">
                                            GET MY FREE LOGO
                                        </span>{" "}
                                        <img
                                            src="assets/images/right-arrow.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;
