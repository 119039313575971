import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { InlineWidget } from "react-calendly";
import {ContextData} from '../ContextData';
import { useState, useContext } from "react";

const Thankyou = () =>{
	const {formData} = useContext(ContextData);

	return(
		<main>
			<section className="section-t-1">
				<div className="container text-center border-bottom">

					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-10">
							<img src="assets/images/big-tick.png" className="img-fluid mb-3" alt="" />
							{(formData.first_name && formData.last_name) && <h1 className="title">{formData.first_name + ' ' + formData.last_name}</h1>}
							<p>Check your email and a logo intake form has been emailed to you. After you fill that out, you will get a logo along with a branding guide. While you wait for the email, here is what else we can do:</p>
						</div>
						<div className="col-md-1"></div>
					</div>
							
				</div>
			</section>

			<section className="section-t-2">
				<div className="container text-center">
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-10">
							<h2 className="title">Book a <span className="textPrimary">free branding</span> intake call</h2>
							<p>with one of our professionals. They can help you quickly fill out the form over the phone. They can also give you some more thoughts and ideas that will help dial in your branding.</p>
						</div>
						<div className="col-md-1"></div>
					</div>
				</div>
			</section>

			<section className="section-t-3">
				<div className="container text-center">
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-10">
							<div className="row align-items-center">
								<div className="col-md-6">
									<div className="calendarActionContent">Book a free branding <br />intake call  <img src="assets/images/curved-arrow.png" className="curvedArrow img-fluid" alt="" /></div>
								

									<InlineWidget
									url="https://calendly.com/klizos/logo-intake?hide_event_type_details=1"
									prefill={{
										email: formData.email || '',
										name: formData.first_name + ' ' + formData.last_name || '',
										customAnswers : {
											a1: formData.phone || ''
										}
									}}
									styles={{
									    height: '630px'
									  }}
									 />
								</div>
								<div className="col-md-6 ps-md-5">
									<Swiper className="reviewSlider"
										slidesPerView={1}
								        spaceBetween={25}
								        
								        loop = {true}
								        navigation={true}
								        modules={[Navigation]}
									>
										<SwiperSlide>
											<div className="contentWrapper text-start">
									    		<div className="mb-2">
									    			<img src="assets/images/rev-logo.png" className="img-fluid" alt="" />
									    		</div>
									    		<div className="authorWrpr">
									    			<div className="name">James T</div>
									    			<div className=""><img src="assets/images/5-star.png" alt="" className="img-fluid" /></div>
									    		</div>
									    		<div className="content">
									    			<p>This company is the best! Had my logo and web page up in no time and for half the cost! I would definitely recommend it!</p>
									    		</div>
									    		
									    	</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className="contentWrapper text-start">
									    		<div className="mb-2">
									    			<img src="assets/images/rev-logo.png" className="img-fluid" alt="" />
									    		</div>
									    		<div className="authorWrpr">
									    			<div className="name">Jesse Jurado</div>
									    			<div className=""><img src="assets/images/5-star.png" alt="" className="img-fluid" /></div>
									    		</div>
									    		<div className="content">
									    			<p>This is an amazing company that helped get my business to the next level. I recommend them to all my business associates.</p>
									    		</div>
									    		
									    	</div>
										</SwiperSlide>
										<SwiperSlide>
											<div className="contentWrapper text-start">
									    		<div className="mb-2">
									    			<img src="assets/images/rev-logo.png" className="img-fluid" alt="" />
									    		</div>
									    		<div className="authorWrpr">
									    			<div className="name">Chris Gonzalez</div>
									    			<div className=""><img src="assets/images/5-star.png" alt="" className="img-fluid" /></div>
									    		</div>
									    		<div className="content">
									    			<p>One of the best companies I've had the pleasure of working with. From start to finish, Joey ensured that my project was delivered on time and met all of our specifications. I was never in the dark and never had to guess where the project stood. Their attention to detail and communication were outstanding. We will absolutely be working with them again. Very highly recommended!</p>
									    		</div>
									    		
									    	</div>
										</SwiperSlide>
										
									</Swiper>
									
									{/*<div className="swiper-button-next">
									  		<img src="assets/images/right-arr.png" height="10" width="12" className="img-fluid" alt="" />
									  	</div>
	  									<div className="swiper-button-prev">
	  										<img src="assets/images/left-arrow.png" height="10" width="12" className="img-fluid" alt="" />
	  										
	  									</div>*/}
								</div>
							</div>
						</div>
						<div className="col-md-1"></div>
					</div>
				</div>
			</section>
		</main>
	)
}

export default Thankyou;