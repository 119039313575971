import Header from './components/snippets/Header';
import Home from './components/Home';
import Footer from './components/snippets/Footer'
import Thankyou from './components/Thankyou'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {ContextData} from './ContextData'
import {  useState } from "react";



function App() {
  const [formData, setFormData] = useState({});

  return (
    <>
      <BrowserRouter>
        <ContextData.Provider value={{formData, setFormData}}>
        
          <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="thankyou" element={<Thankyou />} />
            </Routes>
          <Footer />
        </ContextData.Provider>
      </BrowserRouter>
        
    </>
    
  );
}

export default App;
