import {
  Link
} from "react-router-dom";
const Header = () =>{
	return(
		<header className="header">
			<div className="container">
				<Link to={'/'}>
					<img src="assets/images/header-logo.png" className="img-fluid" alt="klizo solution" />
				</Link>
		
			</div>
		</header>
	)
}

export default Header;